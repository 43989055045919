.landing-section {
  padding: 0px;

  .uk-container {
    padding: 0px;
    min-width: 100%;

    .opacity-container {
      opacity: 0.6;
      background-color: #000;
    }

    .landing-title {
      font-size: 3.2rem;
      font-family: "Barlow", sans-serif;
      font-weight: 600;
      color: $eds-white;
      text-shadow: $eds-blue 0 -2px;
    }

    .underline {
      display: flex;
      justify-content: center;
      text-align: center;

      .line {
        height: 5px;
        background: $eds-yellow;
        width: 30%;
      }
    }

    .landing-text {
      font-weight: 700;
      font-size: 1.6rem;
      color: $eds-yellow;
      // animation: slide-left 4s;
      // @keyframes slide-left {
      //   from {
      //     margin-left: 100%;
      //     width: 300%;
      //   }
      //   to {
      //     margin-left: 0%;
      //     width: 100%;
      //   }
      // }
    }

    .uk-slideshow-nav {
      margin: 5px 0px;

      li {
        a {
          background-color: $eds-yellow !important;
          width: 15px;
          height: 15px;
        }

        &.uk-active {
          a {
            background-color: $eds-blue !important;
            border-color: 2px solid $eds-blue;
          }
        }
      }
    }
  }

  @media only screen and (max-width: 575px) {
    .landing-title {
      font-size: 1rem !important;
    }

    .landing-text {
      font-size: 1rem !important;
    }
  }
}

.service-section {
  background-color: $eds-white;

  .read-more-button {
    background-color: $eds-yellow;
    text-transform: capitalize;
    color: $eds-white;
    padding: 15px 0;
    margin-top: 10px;
    font-size: 16px;
    border-radius: 2px;
    transition: all 0.5s ease-out;

    &:hover {
      background-color: $eds-blue;
    }
  }

  .service-card {
    transition: 0.4s ease-out;
    box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px,
      rgba(17, 17, 26, 0.05) 0px 8px 32px;

    p {
      padding: 1px 0px;
    }

    &:hover {
      transform: translateY(-4px);
      box-shadow: rgba(0, 0, 0, 0.07);
    }
  }
}

.about-us-section {
  background-color: $eds-background;

  .mission-vision {
    border-radius: 4px;
    background-color: $eds-white;
    transition: 0.4s ease-out;
    box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px,
      rgba(17, 17, 26, 0.05) 0px 8px 32px;

    .uk-card-header {
      padding: 0 !important;
      display: flex;
      justify-content: center;
      width: 100%;

      .tab-button {
        border-bottom: 1px solid $eds-dark-grey;
        padding: 3px;
        border-radius: 0 !important;
        margin: 3px;
        transition: 0.4s ease-out;

        .uk-card-title {
          font-weight: 700;
          padding: 0;
          margin: 2px;
          transition: 0.4s ease-out;
        }

        &:hover {
          background-color: $eds-blue;

          .uk-card-title {
            color: $eds-white !important;
          }
        }

        &.active {
          background-color: $eds-yellow;

          .uk-card-title {
            color: $eds-white !important;
          }
        }
      }
    }

    &:hover {
      transform: translateY(-4px);
      box-shadow: 0 1px 20px 0 rgba(128, 128, 128, 0.432);
    }

    p {
      animation: fadeIn 1s;
    }

    @keyframes fadeIn {
      0% {
        opacity: 0;
      }

      100% {
        opacity: 1;
      }
    }
  }

  .values {
    border-radius: 4px;
    background-color: $eds-white;
    transition: 0.4s ease-out;
    box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px,
      rgba(17, 17, 26, 0.05) 0px 8px 32px;

    .uk-card-header {
      padding: 0 !important;
      display: flex;
      justify-content: center;
      width: 100%;

      .uk-card-title {
        font-weight: 700;
        padding: 0;
        margin: 2px;
      }
    }

    .uk-accordion-title {
      border-bottom: 1px solid $eds-dark-grey;
      font-weight: 700;
      color: #222;
      font-size: 16px;
    }

    &:hover {
      transform: translateY(-4px);
      box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.158);
    }

    p {
      animation: fadeIn 1s;
    }

    @keyframes fadeIn {
      0% {
        opacity: 0;
      }

      100% {
        opacity: 1;
      }
    }
  }

  .awards-section {
    .awards-title {
      .uk-card-title {
        font-weight: 700;
        padding: 0;
        margin: 2px;
        text-transform: uppercase;
      }
    }

    .awards {
      box-shadow: none;
      border-radius: 0;
      border-right: 1px solid $eds-dark-grey;
      border-bottom: 1px solid $eds-dark-grey;
      transition: 0.4s ease-out;
      box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px,
        rgba(17, 17, 26, 0.05) 0px 8px 32px;

      &.second {
        border-bottom: none;
      }

      &.last {
        border-right: none;
      }

      &:hover {
        transform: translateY(1.1);
        box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.158);
        z-index: 1040;
        border: none;
        cursor: pointer;
      }
    }
  }
}

.team-section {
  .team-card {
    min-height: 370px;

    .uk-card {
      transition: 0.4s ease-out;
      box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px,
        rgba(17, 17, 26, 0.05) 0px 8px 32px;

      .uk-card-media-top {
        height: auto;
      }

      .bottom-content {
        padding: 10px;
        display: flex;
        justify-content: center;
        text-align: center;

        background-color: $eds-dark-grey;

        background-size: 200% 100%;
        background-position: right bottom;
        transition: all 0.5s ease-out;

        &:hover {
          background-position: left bottom;
        }

        .title {
          color: $eds-white;
          font-weight: 700;
          font-size: 18px !important;
          margin-bottom: 0px;
        }

        p {
          color: $eds-white;
          padding: 0;
          margin: 0 2px;
        }
      }

      &:hover {
        transform: translateY(-4px);
        box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.158);
      }
    }
  }
}

.event-section {
  .event-card-1 {
    .team-card {
      transition: 0.4s ease-out;
      box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px,
        rgba(17, 17, 26, 0.05) 0px 8px 32px;

      .uk-card-media-top {
        height: auto;
      }

      .bottom-content {
        padding: 10px;
        display: flex;
        justify-content: center;
        text-align: center;
        background-color: $eds-yellow;
        background-size: 200% 100%;
        background-position: right bottom;
        transition: all 0.5s ease-out;

        &:hover {
          background-position: left bottom;
        }

        .title {
          color: $eds-white;
          font-weight: 700;
          font-size: 18px !important;
          margin-bottom: 0px;
        }

        p {
          color: $eds-white;
          padding: 0;
          margin: 0 2px;
        }
      }

      &:hover {
        transform: translateY(-4px);
        box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.158);
      }
    }
  }

  .event-card-2 {
    .uk-card {
      .uk-card-media-top {
        display: flex;
        align-items: center;
        justify-content: center;

        button {
          background-color: $eds-yellow;
          font-weight: 600;
          border-radius: 5px;
          transition: all 0.5s ease-out;

          &:hover {
            background-color: $eds-blue;
            text-decoration: none;
          }
        }
      }

      .event-item {
        position: relative;
        cursor: pointer;
        overflow: hidden;
        transition: all 0.3s ease-in-out;
        height: 220px;

        &:hover {
          transform: scale(1.1);
          box-shadow: 0 0 10px rgba($black-color, 0.2);
        }

        img {
          object-fit: contain;
          width: 100%;
          height: 100%;
          transition: all 0.3s ease-in-out;
        }
      }
    }
  }

  .uk-accordion-title::before {
    display: none !important;
  }

  .bottom-content {
    display: flex;
    justify-content: center;
    text-align: center;

    p {
      font-weight: 700;
      color: $eds-black;
      transition-delay: 0s;
      transition-duration: 0.5s;
      transition: all 0.5s ease-out;
      font-size: 25px;
      line-height: 6vh;

      &:hover {
        color: $eds-blue;
        cursor: pointer;
      }
    }
  }
}

.client-section {
  background-color: $eds-background;

  .selected-client-title {
    .uk-card-title {
      font-weight: 700;
      padding: 0;
      margin: 10px 2px;
      text-transform: uppercase;
    }
  }

  .client-section-2 {
    .uk-card {
      border-radius: 0;
      box-shadow: none !important;
      background: linear-gradient(to right, $eds-yellow 50%, $eds-white 50%);
      background-size: 200% 100%;
      background-position: right bottom;
      transition: all 0.4s ease-out;

      &:hover {
        background-position: left bottom;
        transform: translateY(-4px);
        color: $eds-black;
        cursor: pointer;

        .abbrev {
          background: $eds-white;
          color: $eds-black;
        }
      }

      .abbrev {
        width: 70px;
        height: 70px;
        background: $eds-yellow;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $eds-white;
        font-weight: 700;
        transition: all 0.5s ease-out;
      }
    }
  }

  .review-text-card {
    .bg-image {
      filter: blur(8px);
      -webkit-filter: blur(8px);
      height: 100%;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
    }

    .review-text {
      transition-delay: 0s;
      transition-duration: 0.5s;
      transition: all 0.5s ease-out;
      font-size: 50px;
      z-index: 2;

      &:hover {
        color: $eds-blue;
        cursor: pointer;
      }
    }
  }
}

.contact-page {
  background-image: linear-gradient(-180deg,
      $eds-background 50%,
      $eds-yellow 50%);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  .uk-card {
    box-shadow: none;

    .contact-title {
      font-weight: 700;
      padding: 0;
      margin: 10px 10px;
      text-transform: uppercase;
      color: $eds-white;
      font-size: 30px;
    }
  }

  .contact-form {
    box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px,
      rgba(17, 17, 26, 0.05) 0px 8px 32px;

    .uk-select,
    .uk-input,
    .uk-textarea {
      border-radius: 0px !important;
      border-bottom: 1px solid grey;
      border-top: 0px;
      border-left: 0px;
      border-right: 0px;
    }

    .form-button {
      display: flex;
      justify-content: center;

      button {
        font-weight: 700;
        font-size: 16px;
        background-color: $eds-yellow;
        border-radius: 4px;
        color: $eds-white;
        padding: 0px 25px;
        transition: all 0.5s ease-out;

        &:hover {
          background-color: $eds-blue;
        }
      }
    }
  }

  .map {
    box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px,
      rgba(17, 17, 26, 0.05) 0px 8px 32px;

    .maprouter {
      box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px,
        rgba(17, 17, 26, 0.05) 0px 8px 32px;
      position: relative;
      text-align: right;
      height: 500px;
      width: 600px;

      .gmap_canvas {
        overflow: hidden;
        background: none !important;
        height: 500px;
        width: 600px;
      }
    }
  }
}

.service-section-overlay {
  height: 100vh;
  font-family: "Source Sans Pro";
  font-style: normal;
  padding-top: 0px !important;

  .uk-container {
    padding: 0px !important;

    .background-overlay {
      width: 100%;
      height: 100vh;
      position: relative;
      color: white;
      text-align: center;

      img {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 99;
      }

      .overlay {
        width: 100%;
        height: 100vh;
        background: rgba(45, 46, 46, 0.6);
        position: absolute;
        top: 0;
        left: 0;
        z-index: 555;
        opacity: 0.7;
        display: flex;
        justify-content: center;
        align-items: center;

        .landing-title {
          margin: 0 auto 0 auto;
          font-size: 3em;
          font-family: "Barlow", sans-serif;
          font-weight: 600;
          text-transform: uppercase;
          letter-spacing: inherit;
          color: $eds-white;
        }
      }
    }
  }
}

.service-detail-section {
  .services-button {
    background-color: $eds-yellow;
    transition: 0.4s ease-out;
    padding: 10px;
    font-size: 16px;
    font-weight: 700;

    &:hover {
      transform: translateY(-4px);
      box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.158);
      color: $white-color;
    }
  }

  h2 {
    font-size: 25px !important;
    font-weight: 700;
    text-transform: uppercase !important;
    margin: 2px;
  }

  .service-detail {
    .service-detail-card {
      transition: 0.4s ease-out;
      background: linear-gradient(to right, $eds-yellow 50%, $eds-white 50%);
      background-size: 200% 100%;
      background-position: right bottom;

      &:hover {
        background-position: left bottom;
        transform: translateY(-4px);
        box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.158);
        color: $white-color;
      }
    }

    .uk-card {
      transition: 0.4s ease-out;

      &:hover {
        transform: translateY(-4px);
        box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.158);
        cursor: pointer;
      }
    }
  }
}