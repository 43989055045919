.responsive-drawer {
  display: flex;
  height: 100vh;

  .drawer {
    position: fixed;
    z-index: 981;
  }

  main.content {
    flex-grow: 1;
    flex-shrink: 1;
    margin-left: 0;
    width: 100%;
    @media only screen and (min-width: 640px) {
      margin-left: 15em;
    }
  }
}
