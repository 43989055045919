$eds-yellow: #c6d130;
$eds-blue: #1375b4;
$eds-dark-grey: #2d2e2e;
$eds-grey: #ebebe8;
$eds-white: #fff;
$eds-grey-color: #a9a9ab;
$eds-text: #0d0f20;
$eds-white: #fff;
$eds-black: #000;
$eds-background: #f5f6f8;
$eds-small-redius: 8px;
$eds-large-radius: 14px;
$eds-hover: rgb(73, 87, 70);

$button-border-radius: 2px;
$border-radius: 0.25rem;

$font-weight-heading: 600;

$primary-color: #5782c2;
$secondary-color: #2e6ac3;
$darker-secondary-color: #4365ae;
$grey-color: #a9a9ab;
$dark-grey-color: #666;
$light-grey-color: #ccc;
$black-color: #1d1e1f;
$white-color: #fff;
$muted-color: #f8f8fa;
$light-bg: #efefef;
$hover-color: #ffffdd;
$tertiary-color: #fed082;

$success-color: #32d296;
$danger-color: #ed1c24;
$warning-color: #faa05a;

$muli: "Muli";
$playfair: "Playfair Display";

$body-font: $muli;
$heading-font: $playfair;

$link-font: "Rubik", sans-serif;

// Icon Font
$fontAwesome5Brands: "Font Awesome 5 Brands";
$fontAwesome5Pro: "Font Awesome 5 Pro";

// Responsive Variables
$extraBig-device: "only screen and (min-width: 1600px)";
$laptop-device: "only screen and (min-width: 1200px) and (max-width: 1599px)";
$desktop-device: "only screen and (min-width: 992px) and (max-width: 1199px)";
$tablet-device: "only screen and (min-width: 768px) and (max-width: 991px)";
$large-mobile: "only screen and (max-width: 767px)";
$small-mobile: "only screen and (max-width: 575px)";
$extra-small-mobile: "only screen and (max-width: 479px)";
