.footer-page {
  .footer-top {
    background-color: $eds-dark-grey;
    display: flex;
    align-content: center;
    justify-content: center;
    padding: 10px 0px !important;
    min-width: 100%;
    .title {
      text-transform: uppercase;
      color: $eds-white;
    }
    a {
      color: $eds-white !important;
    }
  }
  .footer-bottom {
    background-color: $eds-black;
    display: flex;
    align-content: center;
    justify-content: center;
    padding: 10px 0px !important;
    min-width: 100%;
    .title {
      text-transform: uppercase;
      color: $eds-white;
    }
    a {
      color: $eds-white !important;
    }
  }
}
