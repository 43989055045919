.brand {
  height: 80px;
  width: 100%;
  padding: 5px 30px;
  border-radius: $border-radius;

  img {
    object-fit: contain;
    -o-object-fit: contain;
    object-position: center center;
    -o-object-position: center center;
    height: 100%;
    width: 100%;
  }
}

.drawer {
  width: 0;
  @media only screen and (min-width: 640px) {
    width: 15em;
    flex-grow: 0;
    flex-shrink: 0;
    height: 100%;
  }

  .fixed-drawer {
    height: 100%;
    padding-top: 15px;
    background-color: $eds-dark-grey;
    opacity: 0.8;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.01), 0 3px 6px rgba(0, 0, 0, 0.1);
  }

  .drawer-list {
    ul.main-list {
      list-style: none;
      .list-item {
        justify-items: center;
        border-top: 2px solid $eds-white;
        border-bottom: 2px solid $eds-white;
        transition: all 0.4s ease-out;
        .navlink {
          position: relative;
          margin: 7px 10px;
          display: flex;
          text-transform: uppercase;
          color: $eds-white;
          padding: 5px 0;
          font-weight: 500 !important;
          .number {
            right: 0;
            position: absolute;
            border-spacing: 10px;
            min-width: 40px;
          }
          .number:after {
            content: "";
            position: absolute;
            top: -15px;
            left: -15px;
            right: -15px;
            bottom: -15px;
            border-left: 3px solid white;
          }
        }
        &:hover {
          background-color: $eds-blue;
          color: $eds-white;
          transform: translateX(-4px);
          box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.158);
          cursor: pointer;
          .number:after {
            content: "";
            position: absolute;
            top: -20px;
            left: -15px;
            right: -15px;
            bottom: -15px;
            border-left: 5px solid $eds-yellow;
            height: 20px;
            margin: 20px auto;
          }
        }
      }
    }
  }
}
