* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body {
  margin: 0;
  font-family: "Lato", sans-serif;
  font-weight: 400;
  scroll-behavior: smooth !important;
}

.previous-arrow,
.next-arrow {
  border: 3px solid $eds-blue;
  width: 35px;
  height: 35px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  transition: all 0.5s ease-out;

  &:hover {
    background-color: $eds-yellow;
  }
}

.slider-arrow {
  color: $eds-blue !important;
}

.page-title {
  box-shadow: 0 1px 20px 0 rgba(128, 128, 128, 0.432);
  background-color: $eds-blue;
  color: $eds-white;
  font-weight: 700;
  padding: 10px 10px;
  margin: 15px 1px;
  width: max-content;

  span {
    background-color: $eds-yellow;
    padding: 20px 15px;
  }
}

.selected-clients-card {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  background-color: #fff;
}

.client-card-image {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}